import {inject} from 'vue';
import type {InjectionKey} from 'vue';

export function requireInjection<T>(key: InjectionKey<T>, defaultValue?: T) {
  const resolved = inject(key, defaultValue);

  if (!resolved) {
    throw new Error(`${String(key)} was not provided.`);
  }

  return resolved;
}

export const APP: InjectionKey<'food' | 'drinks' | 'job'> = Symbol('APP');

export const EVENT_ID: InjectionKey<string> = Symbol('EVENT_ID');

export const JOB_COMMITMENT_STATES: InjectionKey<{[key: string]: {icon: string; label: string}}> =
  Symbol('JOB_COMMITMENT_STATES');

export const JOB_PARTICIPATION_STATES: InjectionKey<{[key: string]: {icon: string; label: string}}> =
  Symbol('JOB_PARTICIPATION_STATES');

export const JOB_EVENT_FEATURES: InjectionKey<{[key: string]: string}> = Symbol('JOB_EVENT_FEATURES');

export const POWER_CONNECTION_PROPERTIES: InjectionKey<{[key: string]: string}> = Symbol('POWER_CONNECTION_PROPERTIES');
export const SHORT_POWER_CONNECTION_PROPERTIES: InjectionKey<{[key: string]: string}> = Symbol(
  'SHORT_POWER_CONNECTION_PROPERTIES',
);

export const FOOD_VENDOR_PRODUCT_PROPERTIES: InjectionKey<{[key: string]: string}> = Symbol(
  'FOOD_VENDOR_PRODUCT_PROPERTIES',
);

export const FOOD_VENDOR_PRODUCT_CATEGORIES: InjectionKey<{[key: string]: string}> = Symbol(
  'FOOD_VENDOR_PRODUCT_CATEGORIES',
);

export const FOOD_VENDOR_PACKAGE_PROPERTIES: InjectionKey<{[key: string]: string}> = Symbol(
  'FOOD_VENDOR_PACKAGE_PROPERTIES',
);

export const FOOD_VENDOR_CUTLERY_PROPERTIES: InjectionKey<{[key: string]: string}> = Symbol(
  'FOOD_VENDOR_CUTLERY_PROPERTIES',
);

export const FOOD_VENDOR_ASSORTMENT_CATEGORIES: InjectionKey<string[]> = Symbol('FOOD_VENDOR_ASSORTMENT_CATEGORIES');

export const FOOD_PROJECT_FEATURES: InjectionKey<{[key: string]: string}> = Symbol('FOOD_PROJECT_FEATURES');

export const FOOD_PROJECT_KIOSK_TYPES: InjectionKey<{[key: string]: string}> = Symbol('FOOD_PROJECT_KIOSK_TYPES');

export const FOOD_PROJECT_APPLICATION_STATES: InjectionKey<{
  [key: string]: {icon: string; color: string; label: string};
}> = Symbol('FOOD_PROJECT_APPLICATION_STATES');

export const FOOD_PROJECT_PARTNER_STATES: InjectionKey<{[key: string]: {icon: string; label: string}}> =
  Symbol('FOOD_PROJECT_PARTNER_STATES');
