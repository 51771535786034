/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useFoodProjectStore = defineStore('food_project', () => {
  const applications = shallowRef<Applications>({});
  const areaLocations = shallowRef<AreaLocations>({});
  const areas = shallowRef<Areas>({});
  const assortments = shallowRef<Assortments>({});
  const kiosks = shallowRef<Kiosks>({});
  const partners = shallowRef<Partners>({});
  const invoices = shallowRef<Invoices>({});
  const projectTypes = shallowRef<ProjectTypes>({});
  const projects = shallowRef<Projects>({});
  const myApplications = shallowRef<MyApplications>({});
  const myPartners = shallowRef<MyPartners>({});
  const myInvoices = shallowRef<MyInvoices>({});

  const getApplicationById = generateGetterById<Application>(applications);
  const getAreaLocationById = generateGetterById<AreaLocation>(areaLocations);
  const getAreaById = generateGetterById<Area>(areas);
  const getAssortmentById = generateGetterById<Assortment>(assortments);
  const getKioskById = generateGetterById<Kiosk>(kiosks);
  const getPartnerById = generateGetterById<Partner>(partners);
  const getInvoiceById = generateGetterById<Invoice>(invoices);
  const getProjectTypeById = generateGetterById<ProjectType>(projectTypes);
  const getProjectById = generateGetterById<Project>(projects);
  const getMyApplicationById = generateGetterById<MyApplication>(myApplications);
  const getMyPartnerById = generateGetterById<MyPartner>(myPartners);
  const getMyInvoiceById = generateGetterById<MyInvoice>(myInvoices);

  const fetchProject = async (filter: {project_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-project', projects, filter);
  };

  const fetchProjectsForYear = async (filter: {year: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-projects-for-year', projects, filter);
  };

  const fetchProjectApplications = async (filter: {food_project_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-project-applications', applications, filter);
  };

  const fetchProjectApplicationsFromFoodVendor = async (
    filter: {food_vendor_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-project-applications-from-food-vendor', applications, filter);
  };

  const fetchProjectApplication = async (
    filter: {food_project_application_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-project-application', applications, filter);
  };

  const fetchProjectPartner = async (
    filter: {food_project_partner_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-project-partner', partners, filter);
  };

  const fetchProjectPartners = async (filter: {food_project_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-project-partners', partners, filter);
  };

  const fetchInvoicesFromMonth = async (filter: {month: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-invoices-from-month', invoices, filter);
  };

  const fetchProjectInvoices = async (filter: {food_project_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-project-invoices', invoices, filter);
  };

  const fetchProjectPartnerInvoices = async (
    filter: {food_project_partner_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-project-partner-invoices', invoices, filter);
  };

  const fetchFoodVendorInvoices = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-food-vendor-invoices', invoices, filter);
  };

  const fetchMyInvoices = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-invoices', myInvoices, filter);
  };

  const fetchArea = async (filter: {area_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-area', areas, filter);
  };

  const fetchAreas = async (filter: {project_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-areas', areas, filter);
  };

  const fetchMyEvents = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-events', projects, filter);
  };

  const fetchMyEvent = async (filter: {project_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-event', projects, filter);
  };

  const fetchMyAreas = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-areas', areas, filter);
  };

  const fetchMyProjectApplication = async (
    filter: {food_project_application_id: string; food_vendor_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-project-application', myApplications, filter);
  };

  const fetchMyProjectApplications = async (
    filter: {food_project_id: string; food_vendor_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-project-applications', myApplications, filter);
  };

  const fetchMyApplications = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-applications', myApplications, filter);
  };

  const fetchKiosks = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-kiosks', kiosks, filter);
  };

  const fetchKiosk = async (filter: {food_vendor_kiosk_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-kiosk', kiosks, filter);
  };

  const fetchKiosksFromFoodVendor = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-kiosks-from-food-vendor', kiosks, filter);
  };

  const fetchMyKiosks = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-kiosks', kiosks, filter);
  };

  const fetchMyKiosk = async (
    filter: {food_vendor_kiosk_id: string; food_vendor_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-kiosk', kiosks, filter);
  };

  const fetchMyAssortments = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-assortments', assortments, filter);
  };

  const fetchMyAssortment = async (
    filter: {food_vendor_assortment_id: string; food_vendor_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-assortment', assortments, filter);
  };

  const fetchMyProjectPartner = async (
    filter: {food_project_partner_id: string; food_vendor_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-project-partner', myPartners, filter);
  };

  const fetchMyProjectPartners = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-project.find-my-project-partners', myPartners, filter);
  };

  const initProject = async (data: {
    project_id?: string;
    project_name: string;
    project_type_id: string;
    location_id: string;
    project_schedule: object;
    project_features: object;
  }): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.init-project', data);
    return data['project_id'];
  };

  const publishProject = async (data: {project_id?: string}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.publish-project', data);
    return data['project_id'];
  };

  const withdrawProject = async (data: {project_id?: string}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.withdraw-project', data);
    return data['project_id'];
  };

  const lockProjectSubmissions = async (data: {project_id?: string}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.lock-project-submissions', data);
    return data['project_id'];
  };

  const unlockProjectSubmissions = async (data: {project_id?: string}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.unlock-project-submissions', data);
    return data['project_id'];
  };

  const setProjectName = async (data: {project_id?: string; project_name: string}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.set-project-name', data);
    return data['project_id'];
  };

  const setProjectFeatures = async (data: {project_id?: string; project_features: object}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.set-project-features', data);
    return data['project_id'];
  };

  const setExpectedVisitors = async (data: {project_id?: string; expected_visitors: number}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.set-expected-visitors', data);
    return data['project_id'];
  };

  const setProjectMediaFees = async (data: {project_id?: string; media_fees: string[]}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.set-project-media-fees', data);
    return data['project_id'];
  };

  const setProjectContractFees = async (data: {project_id?: string; contract_fees: object}): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.set-project-contract-fees', data);
    return data['project_id'];
  };

  const setDefaultCostObjectForProject = async (data: {
    project_id?: string;
    cost_object_id: string;
  }): Promise<string> => {
    data['project_id'] ??= generateAggregateId();
    await command('food-project.set-default-cost-object-for-project', data);
    return data['project_id'];
  };

  const setPartnerMediaFees = async (data: {project_partner_id?: string; media_fees: string[]}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.set-partner-media-fees', data);
    return data['project_partner_id'];
  };

  const setPartnerContractFees = async (data: {
    project_partner_id?: string;
    contract_fees: object;
  }): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.set-partner-contract-fees', data);
    return data['project_partner_id'];
  };

  const createPartnerContract = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.create-partner-contract', data);
    return data['project_partner_id'];
  };

  const setContractDownloaded = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.set-contract-downloaded', data);
    return data['project_partner_id'];
  };

  const confirmContract = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.confirm-contract', data);
    return data['project_partner_id'];
  };

  const declineContract = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.decline-contract', data);
    return data['project_partner_id'];
  };

  const createPartnerInvoice = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.create-partner-invoice', data);
    return data['project_partner_id'];
  };

  const setPartnerInvoicePaid = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.set-partner-invoice-paid', data);
    return data['project_partner_id'];
  };

  const cancelPartnerInvoice = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.cancel-partner-invoice', data);
    return data['project_partner_id'];
  };

  const createSalesInvoice = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.create-sales-invoice', data);
    return data['project_partner_id'];
  };

  const setSalesInvoicePaid = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.set-sales-invoice-paid', data);
    return data['project_partner_id'];
  };

  const cancelSalesInvoice = async (data: {project_partner_id?: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.cancel-sales-invoice', data);
    return data['project_partner_id'];
  };

  const addPartnerCost = async (data: {
    project_partner_id?: string;
    cost_id: string;
    cost_amount: number;
    cost_single_price: number;
  }): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.add-partner-cost', data);
    return data['project_partner_id'];
  };

  const removePartnerCost = async (data: {project_partner_id?: string; cost_id: string}): Promise<string> => {
    data['project_partner_id'] ??= generateAggregateId();
    await command('food-project.remove-partner-cost', data);
    return data['project_partner_id'];
  };

  const createArea = async (data: {
    area_id?: string;
    project_id: string;
    area_label: string;
    area_location_id: string;
    is_storage: boolean;
  }): Promise<string> => {
    data['area_id'] ??= generateAggregateId();
    await command('food-project.create-area', data);
    return data['area_id'];
  };

  const setAreaSetupTimes = async (data: {area_id?: string; setup_times: object}): Promise<string> => {
    data['area_id'] ??= generateAggregateId();
    await command('food-project.set-area-setup-times', data);
    return data['area_id'];
  };

  const setAreaDismantlingTimes = async (data: {area_id?: string; dismantling_times: object}): Promise<string> => {
    data['area_id'] ??= generateAggregateId();
    await command('food-project.set-area-dismantling-times', data);
    return data['area_id'];
  };

  const setAreaOperatingHours = async (data: {area_id?: string; operating_hours: object}): Promise<string> => {
    data['area_id'] ??= generateAggregateId();
    await command('food-project.set-area-operating-hours', data);
    return data['area_id'];
  };

  const submitApplication = async (data: {
    application_id?: string;
    project_id: string;
    food_vendor_id: string;
    food_vendor_kiosk_id: string;
    assortment_categories: string[];
    products: object;
  }): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.submit-application', data);
    return data['application_id'];
  };

  const updateApplication = async (data: {
    application_id?: string;
    food_vendor_kiosk_id: string;
    assortment_categories: string[];
    products: object;
  }): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.update-application', data);
    return data['application_id'];
  };

  const addApplicationToShortlist = async (data: {application_id?: string}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.add-application-to-shortlist', data);
    return data['application_id'];
  };

  const markApplicationAsUnlikely = async (data: {application_id?: string}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.mark-application-as-unlikely', data);
    return data['application_id'];
  };

  const acceptApplication = async (data: {application_id?: string}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.accept-application', data);
    return data['application_id'];
  };

  const rejectApplication = async (data: {application_id?: string}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.reject-application', data);
    return data['application_id'];
  };

  const setApplicationMediaFees = async (data: {application_id?: string; media_fees: string[]}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.set-application-media-fees', data);
    return data['application_id'];
  };

  const setApplicationContractFees = async (data: {
    application_id?: string;
    contract_fees: object;
  }): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.set-application-contract-fees', data);
    return data['application_id'];
  };

  const setApplicationAreaLocation = async (data: {
    application_id?: string;
    area_location_id: string;
  }): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.set-application-area-location', data);
    return data['application_id'];
  };

  const setApplicationArea = async (data: {application_id?: string; area_id: string}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.set-application-area', data);
    return data['application_id'];
  };

  const setApplicationKioskNumber = async (data: {application_id?: string; kiosk_number: string}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.set-application-kiosk-number', data);
    return data['application_id'];
  };

  const setApplicationSales = async (data: {application_id?: string; sales: object}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.set-application-sales', data);
    return data['application_id'];
  };

  const setApplicationCashRegisterProducts = async (data: {
    application_id?: string;
    cash_register_products: object;
  }): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('food-project.set-application-cash-register-products', data);
    return data['application_id'];
  };

  const addKiosk = async (data: {
    food_vendor_kiosk_id?: string;
    food_vendor_id: string;
    kiosk_label: string;
    kiosk_type: string;
    dimension: object;
    power_connections: object;
    water_connection: string;
    gas_connection: string;
    additional_power_connections: object;
  }): Promise<string> => {
    data['food_vendor_kiosk_id'] ??= generateAggregateId();
    await command('food-project.add-kiosk', data);
    return data['food_vendor_kiosk_id'];
  };

  const setKioskValues = async (data: {
    food_vendor_kiosk_id?: string;
    kiosk_type: string;
    dimension: object;
    power_connections: object;
    water_connection: string;
    gas_connection: string;
    additional_power_connections: object;
  }): Promise<string> => {
    data['food_vendor_kiosk_id'] ??= generateAggregateId();
    await command('food-project.set-kiosk-values', data);
    return data['food_vendor_kiosk_id'];
  };

  const archiveKiosk = async (data: {food_vendor_kiosk_id?: string; food_vendor_id: string}): Promise<string> => {
    data['food_vendor_kiosk_id'] ??= generateAggregateId();
    await command('food-project.archive-kiosk', data);
    return data['food_vendor_kiosk_id'];
  };

  const updateKiosk = async (data: {
    food_vendor_kiosk_id?: string;
    food_vendor_id: string;
    kiosk_label: string;
  }): Promise<string> => {
    data['food_vendor_kiosk_id'] ??= generateAggregateId();
    await command('food-project.update-kiosk', data);
    return data['food_vendor_kiosk_id'];
  };

  const setKioskNotes = async (data: {food_vendor_kiosk_id?: string; kiosk_notes: string}): Promise<string> => {
    data['food_vendor_kiosk_id'] ??= generateAggregateId();
    await command('food-project.set-kiosk-notes', data);
    return data['food_vendor_kiosk_id'];
  };

  const addKioskPicture = async (data: {
    food_vendor_kiosk_id?: string;
    kiosk_picture_file_key: string;
  }): Promise<string> => {
    data['food_vendor_kiosk_id'] ??= generateAggregateId();
    await command('food-project.add-kiosk-picture', data);
    return data['food_vendor_kiosk_id'];
  };

  const removeKioskPicture = async (data: {
    food_vendor_kiosk_id?: string;
    kiosk_picture_file_key: string;
  }): Promise<string> => {
    data['food_vendor_kiosk_id'] ??= generateAggregateId();
    await command('food-project.remove-kiosk-picture', data);
    return data['food_vendor_kiosk_id'];
  };

  const addAssortment = async (data: {
    food_vendor_assortment_id?: string;
    food_vendor_id: string;
    assortment_label: string;
    assortment_categories: string[];
    products: object;
  }): Promise<string> => {
    data['food_vendor_assortment_id'] ??= generateAggregateId();
    await command('food-project.add-assortment', data);
    return data['food_vendor_assortment_id'];
  };

  const updateAssortment = async (data: {
    food_vendor_assortment_id?: string;
    food_vendor_id: string;
    assortment_label: string;
    assortment_categories: string[];
    products: object;
  }): Promise<string> => {
    data['food_vendor_assortment_id'] ??= generateAggregateId();
    await command('food-project.update-assortment', data);
    return data['food_vendor_assortment_id'];
  };

  return {
    applications,
    areaLocations,
    areas,
    assortments,
    kiosks,
    partners,
    invoices,
    projectTypes,
    projects,
    myApplications,
    myPartners,
    myInvoices,
    getApplicationById,
    getAreaLocationById,
    getAreaById,
    getAssortmentById,
    getKioskById,
    getPartnerById,
    getInvoiceById,
    getProjectTypeById,
    getProjectById,
    getMyApplicationById,
    getMyPartnerById,
    getMyInvoiceById,
    fetchProject,
    fetchProjectsForYear,
    fetchProjectApplications,
    fetchProjectApplicationsFromFoodVendor,
    fetchProjectApplication,
    fetchProjectPartner,
    fetchProjectPartners,
    fetchInvoicesFromMonth,
    fetchProjectInvoices,
    fetchProjectPartnerInvoices,
    fetchFoodVendorInvoices,
    fetchMyInvoices,
    fetchArea,
    fetchAreas,
    fetchMyEvents,
    fetchMyEvent,
    fetchMyAreas,
    fetchMyProjectApplication,
    fetchMyProjectApplications,
    fetchMyApplications,
    fetchKiosks,
    fetchKiosk,
    fetchKiosksFromFoodVendor,
    fetchMyKiosks,
    fetchMyKiosk,
    fetchMyAssortments,
    fetchMyAssortment,
    fetchMyProjectPartner,
    fetchMyProjectPartners,
    initProject,
    publishProject,
    withdrawProject,
    lockProjectSubmissions,
    unlockProjectSubmissions,
    setProjectName,
    setProjectFeatures,
    setExpectedVisitors,
    setProjectMediaFees,
    setProjectContractFees,
    setDefaultCostObjectForProject,
    setPartnerMediaFees,
    setPartnerContractFees,
    createPartnerContract,
    setContractDownloaded,
    confirmContract,
    declineContract,
    createPartnerInvoice,
    setPartnerInvoicePaid,
    cancelPartnerInvoice,
    createSalesInvoice,
    setSalesInvoicePaid,
    cancelSalesInvoice,
    addPartnerCost,
    removePartnerCost,
    createArea,
    setAreaSetupTimes,
    setAreaDismantlingTimes,
    setAreaOperatingHours,
    submitApplication,
    updateApplication,
    addApplicationToShortlist,
    markApplicationAsUnlikely,
    acceptApplication,
    rejectApplication,
    setApplicationMediaFees,
    setApplicationContractFees,
    setApplicationAreaLocation,
    setApplicationArea,
    setApplicationKioskNumber,
    setApplicationSales,
    setApplicationCashRegisterProducts,
    addKiosk,
    setKioskValues,
    archiveKiosk,
    updateKiosk,
    setKioskNotes,
    addKioskPicture,
    removeKioskPicture,
    addAssortment,
    updateAssortment,
  };
});

export interface Project {
  project_id: string;
  project_type_id: string;
  location_id: string;
  project_name: string;
  project_schedule: {start_date: string; end_date: string};
  project_features: string[];
  expected_visitors: number | null;
  media_fees: {[key: string]: number};
  contract_fees: {[key: string]: number};
  cost_object_id: string | null;
  submissions_locked: boolean;
  public: boolean;
}

export interface Application {
  food_project_application_id: string;
  food_project_id: string;
  food_vendor_id: string;
  food_vendor_kiosk_id: string;
  assortment_categories: string[];
  products: Product[];
  cash_register_products: CastRegisterProduct[];
  media_fees: {[key: string]: number};
  contract_fees: {[key: string]: number};
  sales: {[key: string]: number} | null | null;
  area_location_id: string | null;
  area_id: string | null;
  kiosk_number: string | null;
  status: string;
}

export interface ProjectType {
  project_type_id: string;
  project_type_label: string;
  project_type_icon: string;
}

export interface Partner {
  food_project_partner_id: string;
  food_project_id: string;
  food_vendor_id: string;
  media_fees: {[key: string]: number};
  contract_fees: {[key: string]: number};
  costs: {[key: string]: {amount: number; single_price: number}};
  invoice_values: {invoice_number: number; invoice_date: string; partner_fees: PartnerFees};
  status: string[];
}

export interface Invoice {
  invoice_number: string;
  invoice_date: string;
  food_project_partner_id: string;
  food_project_id: string;
  food_vendor_id: string;
  invoice_type: string;
  invoice_values: {partner_fees?: PartnerFees};
  status: string[];
}

export interface MyInvoice {
  invoice_number: string;
  food_project_id: string;
  food_vendor_id: string;
  invoice_type: string;
}

export interface ApplicationFees {
  food_project_application_id: string;
  stand_fees: number;
  stand_fees_gross: number;
  rental_fees: number;
  rental_fees_gross: number;
  media_fees: number;
  media_fees_gross: number;
  revenue_shares_from: number;
  revenue_sharing: number;
}

export interface PartnerFees {
  application_fees: ApplicationFees[];
  stand_fees_sum: number;
  stand_fees_sum_gross: number;
  rental_fees_sum: number;
  rental_fees_sum_gross: number;
  media_fees_sum: number;
  media_fees_sum_gross: number;
  fees_sum: number;
  fees_sum_gross: number;
  vat: number;
  vat_sum: number;
}

export interface AreaLocation {
  area_location_id: string;
  area_location_label: string;
  area_location_type: string;
  is_default: boolean;
}

export interface Area {
  area_id: string;
  project_id: string;
  area_location_id: string;
  area_label: string;
  is_storage: boolean;
  setup_times: object | null;
  dismantling_times: object | null;
  operating_hours: object | null;
}

export interface Kiosk {
  food_vendor_kiosk_id: string;
  food_vendor_id: string;
  label: string;
  type: string;
  dimension: {length: number; depth: number; height: number};
  connections: {power: string[]; water: string; gas: string; power_additional: string[]};
  notes: string | null;
  pictures: string[];
  active: boolean;
}

export interface Assortment {
  food_vendor_assortment_id: string;
  food_vendor_id: string;
  label: string;
  categories: string[];
  products: Product[];
}

export interface Product {
  label: string;
  cutlery: string;
  packaging: string;
  category: string;
  properties: string[];
}

export interface CashRegisterProduct {
  label: string;
  category: string;
  properties: string[];
  price: number;
  tax: number;
}

export interface MyApplication {
  food_project_application_id: string;
  food_project_id: string;
  food_vendor_id: string;
  food_vendor_kiosk_id: string;
  assortment_categories: string[];
  products: Product[];
  cash_register_products: CastRegisterProduct[];
  status: string;
}

export interface MyPartner {
  food_project_partner_id: string;
  food_project_id: string;
  food_vendor_id: string;
  status: string[];
}

export interface Applications {
  [key: string]: Application;
}

export interface AreaLocations {
  [key: string]: AreaLocation;
}

export interface Areas {
  [key: string]: Area;
}

export interface Assortments {
  [key: string]: Assortment;
}

export interface Kiosks {
  [key: string]: Kiosk;
}

export interface Partners {
  [key: string]: Partner;
}

export interface Invoices {
  [key: string]: Invoice;
}

export interface ProjectTypes {
  [key: string]: ProjectType;
}

export interface Projects {
  [key: string]: Project;
}

export interface MyApplications {
  [key: string]: MyApplication;
}

export interface MyPartners {
  [key: string]: MyPartner;
}

export interface MyInvoices {
  [key: string]: MyInvoice;
}
