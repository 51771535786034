/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useCourseStore = defineStore('course', () => {
  const courses = shallowRef<Courses>({});
  const courseModules = shallowRef<CourseModules>({});
  const employeeParticipations = shallowRef<EmployeeParticipations>({});

  const getCourseById = generateGetterById<Course>(courses);
  const getCourseModuleById = generateGetterById<CourseModule>(courseModules);
  const getEmployeeParticipationById = generateGetterById<EmployeeParticipation>(employeeParticipations);

  const fetchCourse = async (filter: {course_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('course.find-course', courses, filter);
  };

  const fetchCoursesForYear = async (filter: {year: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('course.find-courses-for-year', courses, filter);
  };

  const fetchCourseModules = async (filter: {course_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('course.find-course-modules', courseModules, filter);
  };

  const fetchCourseModule = async (filter: {course_module_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('course.find-course-module', courseModules, filter);
  };

  const fetchParticipationsForModule = async (
    filter: {course_module_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('course.find-participations-for-module', employeeParticipations, filter);
  };

  const fetchEmployeeParticipations = async (filter: {course_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('course.find-employee-participations', employeeParticipations, filter);
  };

  const fetchEmployeeParticipation = async (
    filter: {employee_participation_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('course.find-employee-participation', employeeParticipations, filter);
  };

  const initCourse = async (data: {
    course_id?: string;
    location_id: string;
    course_name: string;
    course_day: any;
  }): Promise<string> => {
    data['course_id'] ??= generateAggregateId();
    await command('course.init-course', data);
    return data['course_id'];
  };

  const setCourseName = async (data: {course_id?: string; course_name: string}): Promise<string> => {
    data['course_id'] ??= generateAggregateId();
    await command('course.set-course-name', data);
    return data['course_id'];
  };

  const createModule = async (data: {
    course_module_id?: string;
    course_id: string;
    module_label: string;
    module_period: object;
    qualification_ids: object;
  }): Promise<string> => {
    data['course_module_id'] ??= generateAggregateId();
    await command('course.create-module', data);
    return data['course_module_id'];
  };

  const requestEmployee = async (data: {course_module_id?: string; employee_id: string}): Promise<string> => {
    data['course_module_id'] ??= generateAggregateId();
    await command('course.request-employee', data);
    return data['course_module_id'];
  };

  const appointEmployee = async (data: {course_module_id?: string; employee_id: string}): Promise<string> => {
    data['course_module_id'] ??= generateAggregateId();
    await command('course.appoint-employee', data);
    return data['course_module_id'];
  };

  const acceptModule = async (data: {employee_participation_id?: string}): Promise<string> => {
    data['employee_participation_id'] ??= generateAggregateId();
    await command('course.accept-module', data);
    return data['employee_participation_id'];
  };

  const declineModule = async (data: {employee_participation_id?: string}): Promise<string> => {
    data['employee_participation_id'] ??= generateAggregateId();
    await command('course.decline-module', data);
    return data['employee_participation_id'];
  };

  const dismissModule = async (data: {employee_participation_id?: string}): Promise<string> => {
    data['employee_participation_id'] ??= generateAggregateId();
    await command('course.dismiss-module', data);
    return data['employee_participation_id'];
  };

  const appointModule = async (data: {employee_participation_id?: string}): Promise<string> => {
    data['employee_participation_id'] ??= generateAggregateId();
    await command('course.appoint-module', data);
    return data['employee_participation_id'];
  };

  return {
    courses,
    courseModules,
    employeeParticipations,
    getCourseById,
    getCourseModuleById,
    getEmployeeParticipationById,
    fetchCourse,
    fetchCoursesForYear,
    fetchCourseModules,
    fetchCourseModule,
    fetchParticipationsForModule,
    fetchEmployeeParticipations,
    fetchEmployeeParticipation,
    initCourse,
    setCourseName,
    createModule,
    requestEmployee,
    appointEmployee,
    acceptModule,
    declineModule,
    dismissModule,
    appointModule,
  };
});

export interface Course {
  course_id: string;
  location_id: string;
  course_name: string;
  course_day: string;
}

export interface CourseModule {
  course_module_id: string;
  course_id: string;
  module_label: string;
  module_period: {date: string; from?: string; to?: string};
  qualification_ids: string[];
}

export interface EmployeeParticipation {
  employee_participation_id: string;
  course_id: string;
  course_module_id: string;
  employee_id: string;
  module_date: string;
  status: string | null;
  confirmed: boolean;
  accomplished: boolean;
}

export interface Courses {
  [key: string]: Course;
}

export interface CourseModules {
  [key: string]: CourseModule;
}

export interface EmployeeParticipations {
  [key: string]: EmployeeParticipation;
}
