<template>
  <AppTitle :title="appTitle"></AppTitle>
  <div id="app">
    <header class="app-header">
      <FoodNavigationMenu />
    </header>
    <div class="app-content">
      <router-view></router-view>
    </div>
    <footer class="app-footer"></footer>
  </div>
</template>

<style>
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.app-header {
  margin-bottom: 20px;
}

.app-content {
  flex: 1;
}

.app-footer {
  padding-bottom: 1em;
}

@media only screen and (max-width: 767px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large monitor only']:not(.mobile),
  [class*='widescreen monitor only']:not(.mobile),
  [class*='or lower hidden'] {
    display: none !important;
  }
}
</style>

<script setup lang="ts">
import '@/main.less';
import AppTitle from '@/AppTitle.vue';
import FoodNavigationMenu from '@/components/Navigation/FoodNavigationMenu.vue';
import {
  APP,
  FOOD_PROJECT_APPLICATION_STATES,
  FOOD_PROJECT_FEATURES,
  FOOD_PROJECT_KIOSK_TYPES,
  FOOD_PROJECT_PARTNER_STATES,
  FOOD_VENDOR_ASSORTMENT_CATEGORIES,
  FOOD_VENDOR_CUTLERY_PROPERTIES,
  FOOD_VENDOR_PACKAGE_PROPERTIES,
  FOOD_VENDOR_PRODUCT_CATEGORIES,
  FOOD_VENDOR_PRODUCT_PROPERTIES,
  POWER_CONNECTION_PROPERTIES,
  requireInjection,
  SHORT_POWER_CONNECTION_PROPERTIES,
} from '@/injection_keys';
import {computed, onBeforeMount, onMounted, provide, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useAuthStore} from '@/stores/auth';

const app = requireInjection(APP);

const authStore = useAuthStore();
const router = useRouter();

const appTitle = computed(() => {
  if (app === 'food') {
    return 'Gastrobüro Food';
  }

  if (app === 'drinks') {
    return 'Gastrobüro Drinks';
  }

  return 'Gastrobüro';
});

const powerConnectionProperties = {
  'schuko': 'SchuKo',
  'cee-16a': 'CEE 16A',
  'cee-32a': 'CEE 32A',
  'cee-63a': 'CEE 63A',
  'cee-125a': 'CEE 125A',
};

const shortPowerConnectionProperties = {
  'schuko': 'SchuKo',
  'cee-16a': '16A',
  'cee-32a': '32A',
  'cee-63a': '63A',
  'cee-125a': '125A',
};

const foodProductProperties = {
  'organic': 'bio',
  'gluten-free': 'glutenfrei',
  'lactose-free': 'laktosefrei',
  'regional': 'regional',
};

const foodProductCategories = {
  vegan: 'vegan',
  vegetarian: 'vegetarisch',
  meat: 'Fleisch',
  fish: 'Fisch',
  other: 'andere',
};

const packageProperties = {
  'none': 'keine Verpackung',
  'napkins': 'Servietten - ungebleicht, aus Recyclingpapier',
  'bio-cardboard': 'Verpackung aus Karton, Pappe, Papier - biologisch abbaubar und biobeschichtet',
  'cardboard': 'Verpackung aus Karton, Pappe, Papier - abbaubar und beschichtet',
  'wooden': 'Holz, Holzfasern - FSC zertifiziert, biologisch abbaubar',
  'sugarcane': 'Zuckerrohr - biologisch abbaubar',
  'edible': 'Essbare Verpackungen aus Waffel, Brot, Zucker',
  'reusable': 'Mehrwegverpackungen',
};

const cutleryProperties = {
  none: 'kein Besteck',
  ecological: 'ökologisch abbaubar (Holz, Zuckerrohr, Stärke)',
  edible: 'essbar (Waffel, Brot, Zucker)',
  conventional: 'konventionell (Pappe, Karton)',
  reusable: 'Mehrweg (Metalle, Keramik, Porzellan)',
};

const assortmentCategories = [
  'Asian Food',
  'Breakfast',
  'Brot/Lauge/Handbrot',
  'Burger & BBQ',
  'Drinks',
  'Fisch',
  'Grill',
  'Icecream',
  'Indian Food',
  'Kaffee & Kaffeespezialitäten',
  'Mexikanisch',
  'Pasta/Spätzle',
  'Pizza/Flammkuchen',
  'Pommes',
  'Sweets/Süßwaren',
  'Türkisch/Orientalisch',
  'Vegan',
  'Vegetarisch',
  'Sonstiges',
];

const projectFeatures = {
  vegan: 'vegan',
  vegetarian: 'vegetarisch / vegan',
  cashless: 'cashless',
  no_gas: 'kein Gas erlaubt',
  pagoda_rental: 'Pagodenvermietung',
};

const kioskTypes = {
  'food-truck': 'Foodtruck',
  'sales-trailer': 'Verkaufshänger',
  'ape': 'Ape',
  'pagoda': 'Zelt/Pavillon/Pagode (eigene)',
  'rental-pagoda': 'Mietpagode 5x5m',
  'container': 'Container',
  'bar': 'Bar/Bierwagen/Cocktailstand',
  'mobile': 'Mobilverkauf',
  'other': 'Sonstiges',
};

const foodProjectApplicationStates = {
  requested: {
    icon: 'teal question circle outline',
    color: 'teal',
    label: 'angefragt',
  },
  shortlist: {
    icon: 'yellow thumbs up outline',
    color: 'yellow',
    label: 'engere Auswahl',
  },
  unlikely: {
    icon: 'grey thumbs down outline',
    color: 'grey',
    label: 'eher nicht',
  },
  rejected: {
    icon: 'red calendar minus outline',
    color: 'red',
    label: 'nicht dabei',
  },
  accepted: {
    icon: 'blue calendar check outline',
    color: 'blue',
    label: 'dabei',
  },
};

const foodProjectPartnerStates = {
  contract_created: {
    icon: 'file contract',
    label: 'Vertrag erzeugt',
  },
  contract_downloaded: {
    icon: 'file arrow down',
    label: 'Vertrag heruntergeladen',
  },
  contract_unconfirmed: {
    icon: 'grey file circle question',
    label: 'Vertrag unbestätigt',
  },
  contract_confirmed: {
    icon: 'green file circle check',
    label: 'Vertrag bestätigt',
  },
  contract_declined: {
    icon: 'red file circle xmark',
    label: 'Vertrag abgelehnt',
  },
  invoice_created: {
    icon: 'file invoice icon',
    label: 'Rechnung erzeugt',
  },
};

provide(POWER_CONNECTION_PROPERTIES, powerConnectionProperties);
provide(SHORT_POWER_CONNECTION_PROPERTIES, shortPowerConnectionProperties);
provide(FOOD_VENDOR_PRODUCT_PROPERTIES, foodProductProperties);
provide(FOOD_VENDOR_PRODUCT_CATEGORIES, foodProductCategories);
provide(FOOD_VENDOR_PACKAGE_PROPERTIES, packageProperties);
provide(FOOD_VENDOR_CUTLERY_PROPERTIES, cutleryProperties);
provide(FOOD_VENDOR_ASSORTMENT_CATEGORIES, assortmentCategories);
provide(FOOD_PROJECT_FEATURES, projectFeatures);
provide(FOOD_PROJECT_KIOSK_TYPES, kioskTypes);
provide(FOOD_PROJECT_APPLICATION_STATES, foodProjectApplicationStates);
provide(FOOD_PROJECT_PARTNER_STATES, foodProjectPartnerStates);

const roles = computed(() => authStore.userProfile.roles);

watch([roles, router.currentRoute], () => {
  if (router.currentRoute.value.name !== 'login') {
    return;
  }

  if (authStore.isFoodAdmin) {
    router.push({name: 'food_vendors'});
    return;
  }

  if (authStore.isFoodVendor) {
    router.push({name: 'food_vendor_kiosks'});
    return;
  }
});

onBeforeMount(async () => {
  await Promise.all([authStore.fetchUserProfile()]);
});

onMounted(() => {
  window.addEventListener('vite:preloadError', (event) => {
    event.preventDefault();
    window.location.reload();
  });
});
</script>
