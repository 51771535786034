import {extraErrorDataIntegration, httpClientIntegration} from '@sentry/browser';
import {createApp} from 'vue';
import {pinia} from './stores';
import jobRouter from './router/job';
import foodRouter from './router/food';

import * as Sentry from '@sentry/vue';

import JobApp from './JobApp.vue';
import FoodApp from './FoodApp.vue';
import {APP} from '@/injection_keys.ts';

const parts = window.location.host.split('.');
let rootComponent;
let router;
let rootApp: 'job' | 'food' | 'drinks' = 'job';

switch (parts[0]) {
  case 'food':
    rootComponent = FoodApp;
    rootApp = 'food';
    router = foodRouter;
    break;
  case 'drinks':
    rootComponent = FoodApp;
    rootApp = 'drinks';
    router = foodRouter;
    break;
  default:
    rootComponent = JobApp;
    router = jobRouter;
}

const app = createApp(rootComponent);
app.provide(APP, rootApp);

if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    dsn: 'https://bfb0feb4f4e69c72def497a69c1488f0@o4506427474771968.ingest.sentry.io/4506427476934656',
    integrations: [Sentry.browserTracingIntegration({router}), httpClientIntegration(), extraErrorDataIntegration()],
    trackComponents: true,
  });
}

app.use(router);
app.use(pinia);

app.mount('#app');
