/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useEmployeeStore = defineStore('employee', () => {
  const applications = shallowRef<Applications>({});
  const employees = shallowRef<Employees>({});
  const personnelDatas = shallowRef<PersonnelDatas>({});
  const employeeSearches = shallowRef<EmployeeSearches>({});
  const characteristics = shallowRef<Characteristics>({});

  const getApplicationById = generateGetterById<Application>(applications);
  const getEmployeeById = generateGetterById<Employee>(employees);
  const getPersonnelDataById = generateGetterById<PersonnelData>(personnelDatas);
  const getEmployeeSearchById = generateGetterById<EmployeeSearch>(employeeSearches);
  const getCharacteristicById = generateGetterById<Characteristic>(characteristics);

  const fetchCharacteristics = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('employee.find-characteristics', characteristics, filter);
  };

  const fetchEmployeeApplications = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('employee.find-employee-applications', applications, filter);
  };

  const fetchEmployee = async (filter: {employee_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('employee.find-employee', employees, filter);
  };

  const fetchPersonnelDataFromEmployee = async (
    filter: {employee_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('employee.find-personnel-data-from-employee', personnelDatas, filter);
  };

  const fetchEmployees = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('employee.find-employees', employees, filter);
  };

  const fetchPersonnelDatas = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('employee.find-personnel-datas', personnelDatas, filter);
  };

  const submitApplication = async (data: {
    application_id?: string;
    employee_name: object;
    birthday: any;
    email_address: any;
    mobile_number: any;
    place_of_residence: object;
    find_out_about: string;
  }): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('employee.submit-application', data);
    return data['application_id'];
  };

  const acceptApplication = async (data: {application_id?: string}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('employee.accept-application', data);
    return data['application_id'];
  };

  const declineApplication = async (data: {application_id?: string}): Promise<string> => {
    data['application_id'] ??= generateAggregateId();
    await command('employee.decline-application', data);
    return data['application_id'];
  };

  const setBirthday = async (data: {employee_id?: string; birthday: any}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-birthday', data);
    return data['employee_id'];
  };

  const setTaskScopes = async (data: {employee_id?: string; task_scopes: string[]}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-task-scopes', data);
    return data['employee_id'];
  };

  const setEmployeeTags = async (data: {employee_id?: string; employee_tags: any}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-employee-tags', data);
    return data['employee_id'];
  };

  const requestIdentityCardNumberUpdate = async (data: {
    employee_id?: string;
    identity_card_number: string;
  }): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-identity-card-number-update', data);
    return data['employee_id'];
  };

  const setIdentityCardNumber = async (data: {employee_id?: string; identity_card_number: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-identity-card-number', data);
    return data['employee_id'];
  };

  const requestRegistrationAddressUpdate = async (data: {
    employee_id?: string;
    registration_address: object;
  }): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-registration-address-update', data);
    return data['employee_id'];
  };

  const setRegistrationAddress = async (data: {
    employee_id?: string;
    registration_address: object;
  }): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-registration-address', data);
    return data['employee_id'];
  };

  const requestBankAccountUpdate = async (data: {employee_id?: string; bank_account: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-bank-account-update', data);
    return data['employee_id'];
  };

  const setBankAccount = async (data: {employee_id?: string; bank_account: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-bank-account', data);
    return data['employee_id'];
  };

  const addProfileImage = async (data: {employee_id?: string; profile_image_key: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.add-profile-image', data);
    return data['employee_id'];
  };

  const requestPayrollDataUpdate = async (data: {employee_id?: string; payroll_data: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-payroll-data-update', data);
    return data['employee_id'];
  };

  const setPayrollData = async (data: {employee_id?: string; payroll_data: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-payroll-data', data);
    return data['employee_id'];
  };

  const setEmergencyContact = async (data: {employee_id?: string; emergency_contact: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-emergency-contact', data);
    return data['employee_id'];
  };

  const setContact = async (data: {employee_id?: string; contact: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-contact', data);
    return data['employee_id'];
  };

  const setCharacteristics = async (data: {employee_id?: string; characteristics: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-characteristics', data);
    return data['employee_id'];
  };

  const setRelationshipType = async (data: {employee_id?: string; relationship_type: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-relationship-type', data);
    return data['employee_id'];
  };

  const setWageId = async (data: {employee_id?: string; wage_id: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-wage-id', data);
    return data['employee_id'];
  };

  const setWageProfileId = async (data: {employee_id?: string; wage_profile_id: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-wage-profile-id', data);
    return data['employee_id'];
  };

  const setWageBonusRate = async (data: {employee_id?: string; wage_bonus_rate: number}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-wage-bonus-rate', data);
    return data['employee_id'];
  };

  const removeWageProfileId = async (data: {employee_id?: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.remove-wage-profile-id', data);
    return data['employee_id'];
  };

  const setFirstEffortDate = async (data: {employee_id?: string; first_effort_date: any}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-first-effort-date', data);
    return data['employee_id'];
  };

  const setEmploymentEndDate = async (data: {employee_id?: string; employment_end_date: any}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-employment-end-date', data);
    return data['employee_id'];
  };

  const deactivateEmployee = async (data: {employee_id?: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.deactivate-employee', data);
    return data['employee_id'];
  };

  const activateEmployee = async (data: {employee_id?: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.activate-employee', data);
    return data['employee_id'];
  };

  const setEmployeeName = async (data: {employee_id?: string; employee_name: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-employee-name', data);
    return data['employee_id'];
  };

  const setEmployeeNotes = async (data: {employee_id?: string; employee_notes: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-employee-notes', data);
    return data['employee_id'];
  };

  const addEmployeeToStaffPool = async (data: {employee_id?: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.add-employee-to-staff-pool', data);
    return data['employee_id'];
  };

  const removeEmployeeFromStaffPool = async (data: {employee_id?: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.remove-employee-from-staff-pool', data);
    return data['employee_id'];
  };

  const requestHealthInsuranceUpdate = async (data: {
    employee_id?: string;
    health_insurance: object;
  }): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-health-insurance-update', data);
    return data['employee_id'];
  };

  const setHealthInsurance = async (data: {employee_id?: string; health_insurance: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-health-insurance', data);
    return data['employee_id'];
  };

  const requestGenderUpdate = async (data: {employee_id?: string; gender: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-gender-update', data);
    return data['employee_id'];
  };

  const setGender = async (data: {employee_id?: string; gender: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-gender', data);
    return data['employee_id'];
  };

  const requestPersonalTaxNumberUpdate = async (data: {
    employee_id?: string;
    personal_tax_number: string;
  }): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-personal-tax-number-update', data);
    return data['employee_id'];
  };

  const setPersonalTaxNumber = async (data: {employee_id?: string; personal_tax_number: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-personal-tax-number', data);
    return data['employee_id'];
  };

  const recordAccounting = async (data: {employee_id?: string}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.record-accounting', data);
    return data['employee_id'];
  };

  const registerTaxOffice = async (data: {employee_id?: string; tax_office_register_date: any}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.register-tax-office', data);
    return data['employee_id'];
  };

  const requestPensionInsuranceExemptionUpdate = async (data: {
    employee_id?: string;
    pension_insurance_exemption: boolean;
  }): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-pension-insurance-exemption-update', data);
    return data['employee_id'];
  };

  const setPensionInsuranceExemption = async (data: {
    employee_id?: string;
    pension_insurance_exemption: boolean;
  }): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-pension-insurance-exemption', data);
    return data['employee_id'];
  };

  const requestFurtherEmploymentsUpdate = async (data: {
    employee_id?: string;
    further_employments: object;
  }): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.request-further-employments-update', data);
    return data['employee_id'];
  };

  const setFurtherEmployments = async (data: {employee_id?: string; further_employments: object}): Promise<string> => {
    data['employee_id'] ??= generateAggregateId();
    await command('employee.set-further-employments', data);
    return data['employee_id'];
  };

  return {
    applications,
    employees,
    personnelDatas,
    employeeSearches,
    characteristics,
    getApplicationById,
    getEmployeeById,
    getPersonnelDataById,
    getEmployeeSearchById,
    getCharacteristicById,
    fetchCharacteristics,
    fetchEmployeeApplications,
    fetchEmployee,
    fetchPersonnelDataFromEmployee,
    fetchEmployees,
    fetchPersonnelDatas,
    submitApplication,
    acceptApplication,
    declineApplication,
    setBirthday,
    setTaskScopes,
    setEmployeeTags,
    requestIdentityCardNumberUpdate,
    setIdentityCardNumber,
    requestRegistrationAddressUpdate,
    setRegistrationAddress,
    requestBankAccountUpdate,
    setBankAccount,
    addProfileImage,
    requestPayrollDataUpdate,
    setPayrollData,
    setEmergencyContact,
    setContact,
    setCharacteristics,
    setRelationshipType,
    setWageId,
    setWageProfileId,
    setWageBonusRate,
    removeWageProfileId,
    setFirstEffortDate,
    setEmploymentEndDate,
    deactivateEmployee,
    activateEmployee,
    setEmployeeName,
    setEmployeeNotes,
    addEmployeeToStaffPool,
    removeEmployeeFromStaffPool,
    requestHealthInsuranceUpdate,
    setHealthInsurance,
    requestGenderUpdate,
    setGender,
    requestPersonalTaxNumberUpdate,
    setPersonalTaxNumber,
    recordAccounting,
    registerTaxOffice,
    requestPensionInsuranceExemptionUpdate,
    setPensionInsuranceExemption,
    requestFurtherEmploymentsUpdate,
    setFurtherEmployments,
  };
});

export interface Application {
  application_id: string;
  properties: {
    employee_name: {first_name: string; last_name: string};
    birthday: string;
    email_address: string;
    mobile_number: string;
    place_of_residence: {postal_routing_number: string; town: string};
  };
}

export interface PersonnelData {
  employee_id: string;
  full_name: string;
  email_address: string | null;
  birthday: string | null;
  place_of_residence: {postal_routing_number: string; town: string} | null;
  avatar: string | null;
  characteristics: string[];
  properties: {
    contact?: {mobile_number: string};
    emergency_contact?: {mobile_number: string; contact_person: string};
    identity_card_number?: string;
    personal_tax_number?: string;
    bank_account?: {iban: string; holder: string};
    payroll_data?: {
      nationality: string;
      marital_status: string;
      place_of_birth: string;
      country_of_birth: string;
      name_of_birth: string;
      employment_status: string;
      social_insurance_number: string;
    };
    health_insurance?: {type: string; name: string};
    gender?: string;
    pension_insurance_exemption?: bool;
    registration_address?: {
      town: string;
      address_addition: string;
      postal_routing_number: string;
      street_and_house_number: string;
    };
    further_employments?: {type: string; hours: string}[];
  };
  update_requests: {
    registration_address?: {
      town: string;
      address_addition: string;
      postal_routing_number: string;
      street_and_house_number: string;
    };
    identity_card_number?: string;
    personal_tax_number?: string;
    bank_account?: {iban: string; holder: string};
    payroll_data?: {
      nationality: string;
      marital_status: string;
      place_of_birth: string;
      country_of_birth: string;
      name_of_birth: string;
      employment_status: string;
      social_insurance_number: string;
    };
    health_insurance?: {type: string; name: string};
    gender?: string;
    further_employments?: {type: string; hours: string}[];
  };
}

export interface Employee {
  employee_id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  full_aged_from: string | null;
  avatar: string | null;
  place_of_residence: {postal_routing_number: string; town: string} | null;
  task_scopes: string[];
  first_effort_date: string | null;
  effort_amount: number;
  last_efforts: {task_id: string; kiosk_id: string | null; effort_day: string}[];
  wage_id: string | null;
  wage_profile_id: string | null;
  wage_bonus_rate: number;
  relationship_type: string | null;
  employment_ends_at: string | null;
  tags: string[];
  notes: string;
  has_conflicts: boolean;
  has_updates: boolean;
  recorded_in_accounting: boolean;
  registered_with_tax_office: string | null;
  pool: boolean;
  active: boolean;
}

export interface MyEmployee {
  employee_id: string;
  first_effort_date: string | null;
  effort_amount: number;
}

export interface EmployeeSearch {
  employee_id: string;
  full_name: string;
  full_aged_from: string | null;
  avatar: string | null;
}

export interface Characteristic {
  characteristic_id: string;
  characteristic_type: string;
  characteristic_label: string;
  characteristic_info: string | null;
}

export interface Applications {
  [key: string]: Application;
}

export interface Employees {
  [key: string]: Employee;
}

export interface PersonnelDatas {
  [key: string]: PersonnelData;
}

export interface EmployeeSearches {
  [key: string]: EmployeeSearch;
}

export interface Characteristics {
  [key: string]: Characteristic;
}
