import {useAccountingStore} from '@/stores/generated/accounting';
import {useAuthStore} from '@/stores/auth';
import {useCourseStore} from '@/stores/generated/course';
import {useEmployeeStore} from '@/stores/generated/employee';
import {useEmployeeQualificationStore} from '@/stores/generated/employee_qualification';
import {useEmploymentContractStore} from '@/stores/generated/employment_contract';
import {useEventStore} from '@/stores/generated/event';
import {useFoodProjectStore} from '@/stores/generated/food_project';
import {useFoodVendorStore} from '@/stores/generated/food_vendor';
import type {PiniaPluginContext} from 'pinia';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const lazyPoll = async (fn: () => Promise<any>) => {
  await sleep(1000);
  await fn();
};

export const AutoPollPlugin = (context: PiniaPluginContext) => {
  context.store.$onAction(({name, store, args, after}) => {
    // console.log(name, args);

    const lazyPollAfter = (fn: () => Promise<any>) => after(async () => await lazyPoll(fn));

    const resetTenantWorkers = () => {
      after(async () =>
        useEventStore().$patch((state) => {
          state.tenantWorkers = {};
        }),
      );
    };

    const resetCoWorker = (coWorkerId: string) => {
      after(async () =>
        useEventStore().$patch((state) => {
          const coWorkers = {...state.coWorkers};
          delete coWorkers[coWorkerId];
          state.coWorkers = coWorkers;
        }),
      );
    };

    const resetApplication = (applicationId: string) => {
      after(async () =>
        useEmployeeStore().$patch((state) => {
          const applications = {...state.applications};
          delete applications[applicationId];
          state.applications = applications;
        }),
      );
    };

    const resetSettlement = (settlementId: string) => {
      after(async () =>
        useAccountingStore().$patch((state) => {
          const settlements = {...state.settlements};
          delete settlements[settlementId];
          state.settlements = settlements;
        }),
      );
    };

    if ('food_project' === store.$id) {
      switch (name) {
        case 'initProject':
        case 'setProjectName':
        case 'setProjectFeatures':
        case 'setExpectedVisitors':
        case 'setProjectMediaFees':
        case 'setProjectContractFees':
        case 'setDefaultCostObjectForProject':
        case 'publishProject':
        case 'withdrawProject':
        case 'lockProjectSubmissions':
        case 'unlockProjectSubmissions':
          after(() =>
            useFoodProjectStore().fetchProject({
              project_id: args[0]['project_id'],
            }),
          );
          break;
        case 'createArea':
        case 'setAreaSetupTimes':
        case 'setAreaDismantlingTimes':
        case 'setAreaOperatingHours':
          after(() =>
            useFoodProjectStore().fetchArea({
              area_id: args[0]['area_id'],
            }),
          );
          break;
        case 'submitApplication':
        case 'updateApplication':
          after(() => {
            const authStore = useAuthStore();

            if (authStore.isFoodAdmin) {
              useFoodProjectStore().fetchProjectApplication({
                food_project_application_id: args[0]['application_id'],
              });
            }

            if (authStore.isFoodVendor) {
              useFoodProjectStore().fetchMyProjectApplication({
                food_project_application_id: args[0]['application_id'],
                food_vendor_id: args[0]['food_vendor_id'],
              });
            }
          });
          break;
        case 'addApplicationToShortlist':
        case 'markApplicationAsUnlikely':
        case 'acceptApplication':
        case 'rejectApplication':
        case 'setApplicationKioskNumber':
        case 'setApplicationArea':
        case 'setApplicationSales':
          after(() =>
            useFoodProjectStore().fetchProjectApplication({
              food_project_application_id: args[0]['application_id'],
            }),
          );
          break;
        case 'addKiosk':
        case 'setKioskValues':
        case 'archiveKiosk':
        case 'updateKiosk':
        case 'setKioskNotes':
        case 'removeKioskPicture':
          after(() => {
            const authStore = useAuthStore();

            if (authStore.isFoodAdmin) {
              return useFoodProjectStore().fetchKiosk({
                food_vendor_kiosk_id: args[0]['food_vendor_kiosk_id'],
              });
            }

            return useFoodProjectStore().fetchMyKiosk({
              food_vendor_id: useAuthStore().userProfile.food_vendor_id || '',
              food_vendor_kiosk_id: args[0]['food_vendor_kiosk_id'],
            });
          });
          break;
        case 'addAssortment':
        case 'updateAssortment':
          after(() =>
            useFoodProjectStore().fetchMyAssortment({
              food_vendor_id: useAuthStore().userProfile.food_vendor_id || '',
              food_vendor_assortment_id: args[0]['food_vendor_assortment_id'],
            }),
          );
          break;
        case 'setContractDownloaded':
        case 'confirmContract':
        case 'declineContract':
          after(() =>
            useFoodProjectStore().fetchMyProjectPartner({
              food_vendor_id: useAuthStore().userProfile.food_vendor_id || '',
              food_project_partner_id: args[0]['project_partner_id'],
            }),
          );
          break;
        case 'createPartnerContract':
        case 'setPartnerMediaFees':
        case 'setPartnerContractFees':
        case 'addPartnerCost':
        case 'removePartnerCost':
          after(() =>
            useFoodProjectStore().fetchProjectPartner({
              food_project_partner_id: args[0]['project_partner_id'],
            }),
          );
          break;
        case 'setApplicationMediaFees':
        case 'setApplicationContractFees':
          after(() =>
            useFoodProjectStore().fetchProjectApplication({
              food_project_application_id: args[0]['application_id'],
            }),
          );
          break;
        case 'createPartnerInvoice':
        case 'cancelPartnerInvoice':
        case 'setPartnerInvoicePaid':
        case 'createSalesInvoice':
        case 'cancelSalesInvoice':
        case 'setSalesInvoicePaid':
          after(() => {
            useFoodProjectStore().fetchProjectPartner({
              food_project_partner_id: args[0]['project_partner_id'],
            });
            useFoodProjectStore().fetchProjectPartnerInvoices({
              food_project_partner_id: args[0]['project_partner_id'],
            });
          });
          break;
      }

      return;
    }

    if ('food_vendor' === store.$id) {
      switch (name) {
        case 'addContactPerson':
          after(() =>
            useFoodVendorStore().fetchContactPerson({
              food_vendor_id: args[0]['food_vendor_id'],
              contact_person_id: args[0]['contact_person_id'],
            }),
          );
          break;
        case 'setCompanyName':
        case 'setFoodVendorNotes':
        case 'setFoodVendorTags':
        case 'setVendorCategories':
          after(() =>
            useFoodVendorStore().fetchFoodVendor({
              food_vendor_id: args[0]['food_vendor_id'],
            }),
          );
          break;
        case 'setFoodVendorBankAccount':
        case 'setCompanyAddress':
          after(() => {
            const authStore = useAuthStore();

            if (authStore.isFoodAdmin) {
              return useFoodVendorStore().fetchFoodVendor({
                food_vendor_id: args[0]['food_vendor_id'],
              });
            }

            return useFoodVendorStore().fetchMyFoodVendor({
              food_vendor_id: args[0]['food_vendor_id'],
            });
          });
          break;
      }

      return;
    }

    if ('employee_qualification' === store.$id) {
      switch (name) {
        case 'createQualification':
          after(() =>
            useEmployeeQualificationStore().fetchQualification({
              qualification_id: args[0]['qualification_id'],
            }),
          );
          break;
        case 'confirmQualification':
          after(() =>
            useEmployeeQualificationStore().fetchQualificationsFromEmployee({
              employee_id: args[0]['employee_qualification_id'],
            }),
          );
          break;
      }

      return;
    }

    if ('course' === store.$id) {
      switch (name) {
        case 'initCourse':
        case 'setCourseName':
          after(() =>
            useCourseStore().fetchCourse({
              course_id: args[0]['course_id'],
            }),
          );
          break;
        case 'createModule':
          after(() =>
            useCourseStore().fetchCourseModule({
              course_module_id: args[0]['course_module_id'],
            }),
          );
          break;
        case 'requestEmployee':
        case 'appointEmployee':
          after(() => {
            store.fetchParticipationsForModule({course_module_id: args[0]['course_module_id']});
            useEmployeeStore().fetchEmployee({employee_id: args[0]['employee_id']});
          });
          break;
        case 'acceptModule':
        case 'declineModule':
          after(() =>
            useCourseStore().fetchEmployeeParticipation({
              employee_participation_id: args[0]['employee_participation_id'],
              employee_id: args[0]['employee_id'],
            }),
          );
          break;
        case 'appointModule':
        case 'dismissModule':
          after(() =>
            useCourseStore().fetchEmployeeParticipation({
              employee_participation_id: args[0]['employee_participation_id'],
            }),
          );
          break;
      }

      return;
    }

    switch (name) {
      case 'initEvent':
      case 'cancelEvent':
      case 'setEventName':
      case 'setEventDescription':
      case 'setEventFeatures':
      case 'setDefaultCostCenterForEvent':
      case 'setDefaultCostObjectForEvent':
        after(() => store.fetchEvent({event_id: args[0]['event_id']}));
        break;
      case 'setEventTaskTargetFigure':
        lazyPollAfter(() => store.fetchEvent({event_id: args[0]['event_task_target_id']}));
        break;
      case 'createLocation':
      case 'updateLocation':
      case 'setLocationQualifications':
      case 'archiveLocation':
        after(() => store.fetchLocations());
        break;
      case 'createTaskScope':
        after(() => store.fetchTaskScopes());
        break;
      case 'createTask':
      case 'setWageProfile':
      case 'setTaskDescription':
      case 'setTaskQualifications':
      case 'archiveTask':
        after(() => store.fetchTask({task_id: args[0]['task_id']}));
        break;
      case 'archiveTaskScope':
        after(() => store.fetchTaskScopes());
        after(() => store.fetchTasks());
        break;
      case 'createKiosk':
      case 'archiveKiosk':
        after(() => store.fetchKiosk({kiosk_id: args[0]['kiosk_id']}));
        break;
      case 'createAgency':
      case 'archiveAgency':
      case 'setHourlyRate':
        after(() => store.fetchAgencies());
        break;
      case 'createTenant':
      case 'archiveTenant':
        after(() => store.fetchTenants());
        break;
      case 'addWageProfile':
      case 'disableWageProfile':
        after(() => store.fetchWageProfiles());
        break;
      case 'addTaxProfile':
      case 'disableTaxProfile':
        after(() => store.fetchTaxProfiles());
        break;
      case 'createCost':
        after(() => store.fetchCosts());
        break;
      case 'addCostCenter':
        after(() => store.fetchCostCenters());
        break;
      case 'addCostObject':
        after(() => store.fetchCostObjects());
        break;
      case 'createShift':
      case 'cancelShift':
      case 'updateShiftPeriod':
      case 'setShiftTargetFigure':
        after(() => store.fetchShift({shift_id: args[0]['shift_id']}));
        break;
      case 'appointShift':
      case 'dismissShift':
      case 'replaceShift':
        after(() => store.fetchCommitment({commitment_id: args[0]['commitment_id']}));
        break;
      case 'setCommitmentEffort':
      case 'setCommitmentTravelExpenses':
        after(() =>
          store.fetchCommitmentEffort({
            commitment_id: args[0]['commitment_id'],
          }),
        );
        break;
      case 'requestEmployee':
      case 'appointEmployee':
        after(() => {
          store.fetchCommitmentsForShift({shift_id: args[0]['shift_id']});
          useEmployeeStore().fetchEmployee({employee_id: args[0]['employee_id']});
        });
        break;
      case 'requestCoWorker':
        after(() => store.fetchCoWorkersForShift({shift_id: args[0]['shift_id']}));
        break;
      case 'dismissCoWorker':
        resetCoWorker(args[0]['co_worker_id']);
        break;
      case 'closeKiosk':
        after(() =>
          store.fetchKioskOperation({
            kiosk_operation_id: args[0]['kiosk_operation_id'],
          }),
        );
        break;
      case 'requireKiosk':
        after(() => store.fetchKioskOperations({event_id: args[0]['event_id']}));
        break;
      case 'dismissShiftFromKiosk':
      case 'requireShiftInKiosk':
      case 'setKioskTenant':
      case 'removeKioskTenant':
        resetTenantWorkers();
        after(() =>
          store.fetchKioskOperation({
            kiosk_operation_id: args[0]['kiosk_operation_id'],
          }),
        );
        break;
      case 'replaceCoWorkerShift':
      case 'setCoWorkerName':
      case 'setCoWorkerBirthday':
      case 'setCoWorkerBirthplace':
      case 'setCoWorkerRemarks':
        after(() =>
          store.fetchCoWorker({
            co_worker_id: args[0]['co_worker_id'],
          }),
        );
        break;
      case 'setTenantWorkerName':
      case 'setTenantWorkerBirthday':
      case 'setTenantWorkerBirthplace':
      case 'setTenantWorkerAddress':
      case 'setTenantWorkerIdNumber':
      case 'setTenantWorkerClothesSize':
        after(() =>
          store.fetchTenantWorker({
            tenant_worker_id: args[0]['tenant_worker_id'],
          }),
        );
        break;
      case 'setCommitmentKiosk':
      case 'removeCommitmentKiosk':
        after(() =>
          store.fetchCommitment({
            commitment_id: args[0]['commitment_id'],
          }),
        );
        break;
      case 'setCoWorkerKiosk':
      case 'removeCoWorkerKiosk':
        after(() =>
          store.fetchCoWorker({
            co_worker_id: args[0]['co_worker_id'],
          }),
        );
        break;
      case 'checkInWorker':
      case 'checkOutWorker':
      case 'setWorkerAttendanceNotes':
      case 'assignWorkerAttendanceAccreditation':
        after(() =>
          store.fetchWorkerAttendance({
            worker_attendance_id: args[0]['worker_attendance_id'],
          }),
        );
        break;
      case 'issueCashRegisterCard':
      case 'setCashRegisterCardReceipts':
      case 'regainCashRegisterCard':
        after(() =>
          store.fetchCashRegisterCard({
            cash_register_card_id: args[0]['cash_register_card_issuance_id'],
          }),
        );
        break;
      case 'setRegistrationAddress':
      case 'setHealthInsurance':
      case 'setPayrollData':
      case 'setGender':
      case 'setPersonalTaxNumber':
        after(() =>
          store.fetchPersonnelDataFromEmployee({
            employee_id: args[0]['employee_id'],
          }),
        );
        break;
      case 'acceptApplication':
      case 'declineApplication':
        resetApplication(args[0]['application_id']);
        break;
      case 'createEmploymentContract':
      case 'confirmEmploymentContract':
      case 'confirmEmploymentDocument':
        after(() => {
          useEmploymentContractStore().fetchEmploymentContract({
            employment_contract_id: args[0]['employment_contract_id'],
          });
        });
        break;
      case 'withdrawSettlement':
        resetSettlement(args[0]['settlement_id']);
        break;
      case 'deactivateEmployee':
      case 'activateEmployee':
      case 'removeEmployeeFromStaffPool':
      case 'addEmployeeToStaffPool':
      case 'setEmployeeTags':
      case 'setEmployeeName':
      case 'registerTaxOffice':
        after(() =>
          store.fetchEmployee({
            employee_id: args[0]['employee_id'],
          }),
        );
        break;
    }
  }, true);
};
